@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.dateTitle {
  letter-spacing: 1px;
  font-weight: var(--fontWeightMedium);
  margin: 0 24px;
  @media (--viewportMedium) {
    margin: 0;
  }
}

.slotsBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.arrowLeft,
.arrowRight {
  flex-basis: 50px;
  display: flex;
  cursor: pointer;

  & .arrow {
    margin: auto;
  }
  @media (--viewportMedium) {
    flex-basis: 20px;
  }
}

.slotsList {
  width: 100%;
  min-height: 110px;
}

.slotsBox {
  cursor: pointer;
  &.singleSlot {
    & .slotsList {
      min-height: auto;
      & .timeSlot {
        cursor: pointer;
        margin: 0 24px;
        @media (--viewportMedium) {
          margin: 0;
        }
      }
    }
    & .arrowLeft,
    .arrowRight {
      display: none;
    }
  }
}

.timeSlot {
  text-align: center;
  margin: 5px;
  background: white;
  line-height: 30px;
}

.timeSlotHighLight {
  border: 1px solid black;
}
